import React from 'react'

import { Layout } from 'components/global'
import { Hero, HomeBody, Images, MeetTheTeam } from 'components/blocks'

const IndexPage = () => {
  const meta = {
    title: `Home | Dorothy Dandridge`,
  }

  return (
    <Layout title={meta.title}>
      <h1 className="sr-only">Dorothy Dandridge</h1>
      <Hero />
      <HomeBody />
      <MeetTheTeam />
      <Images />
      <div className="w-full bg-black ">
        <div className="w-[85%] py-6 md:text-[20px] mx-auto container max-w-[1100px] text-center md:py-12">
          <p>
            <strong>Select Roles:</strong> Dorothy starred in{' '}
            <strong>Porgy and Bess</strong> with Sidney Poitier, was nominated
            for a best actress Oscar for <strong>Carmen Jones</strong> with
            Harry Belafonte, and featured in{' '}
            <strong>Sun Valley Serenade</strong> with the Nicholas Brothers
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
